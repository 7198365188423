import { NitTools } from "../classes/NursitTools";
import { FhirService } from "./FhirService";
import SystemHeaders from "../classes/SystemHeaders";
import { UserService } from "./UserService";
import { RuntimeInfo } from "../classes/RuntimeInfo";
import { ConfigService } from "./ConfigService";
import {Tools} from "../classes/FhirModules/Tools";

export class LocationService {
    public get beds(): any[] {
        return this.filterByType("bd");
    }

    public get rooms(): any[] {
        return this.filterByType("ro");
    }

    public get wards(): any[] {
        return this.filterByType("wa");
    }

    static __staticLocationsList: any[] = undefined;

    private get _locations(): any[] {
        return LocationService.__staticLocationsList;
    }

    private set _locations(locations: any[]) {
        LocationService.__staticLocationsList = locations;
    }

    fhirService: FhirService;
    private checkedUseSystem: boolean = false;
    private useSystem: boolean = false;

    private static loadingLocations = false;

    constructor() {
        this.fhirService = new FhirService();
    }

    private static waitForService(): Promise<void> {
        return new Promise<void>(async (resolve) => {
            if (!this.loadingLocations) {
                resolve();
            } else {
                window.setTimeout(async () => {
                    await this.waitForService();
                    resolve();
                }, 100);
            }
        })
    }

    private filterByType(type: string): any[] {
        if (!this._locations) return [];
        return this._locations.filter(o => o.physicalType && o.physicalType.coding && o.physicalType.coding.length > 0 && o.physicalType.coding[0].code === type);
    }


    public get hasLocations(): boolean {
        if (!this._locations) return false;

        return this._locations.length > 0;
    }

    public getLocationById(idOrReference: string): any {
        if (!this._locations) return undefined;
        let shortId = idOrReference;
        shortId = Tools.StripId(idOrReference);
        idOrReference = idOrReference.split('/_history')[0];

        return this._locations.find(o => o.id === idOrReference || o.id === shortId);
    }

    public clear() {
        this._locations = undefined;
    }

    public static EnsureLocationIdentifier(loc): boolean {
        let result = false;

        if (!loc.identifier) loc.identifier = [];
        let systemString = NitTools.ExcludeTrailingSlash(RuntimeInfo.SystemHeader);
        if (loc.physicalType && loc.physicalType.coding && loc.physicalType.coding[0] && loc.physicalType.coding[0].code) {
            switch (loc.physicalType.coding[0].code.toUpperCase()) {
                case 'WA':
                    systemString += '/locationWard';
                    break;
                case 'RO':
                    systemString += '/locationRoom';
                    break;
                case 'BD':
                    systemString += '/locationBed';
                    break;
                default: // when no machting type, don't dare to touch the location:
                    return result;
            }

            let existingLocationIdentifier = loc.identifier.find(o => o.system && o.system.toUpperCase() === systemString.toUpperCase());
            if (!existingLocationIdentifier) {
                loc.identifier.push({
                    use: 'official',
                    system: systemString,
                    value: loc.id || loc.name
                });

                result = true;
            }
        }

        return result;
    }

    public static InsertLocation(location, force: boolean = false) {
        if (!location) return;

        if (!location.status && ConfigService.cfg && ConfigService.cfg.features && ConfigService.cfg.features.assumeLocationsWithoutStatusAs)
            location.status = ConfigService.cfg.features.assumeLocationsWithoutStatusAs;

        if (location.status !== "active" && location.status !== "suspended") {
            if (!force)
                return;
        }

        if (!this.__staticLocationsList) this.__staticLocationsList = [];
        if (typeof this.__staticLocationsList.find(o => o.id === location.id) !== "undefined") return;

        this.EnsureLocationIdentifier(location);

        this.__staticLocationsList.push(location);
    }

    public insertLocation(location) {
        LocationService.InsertLocation(location);
    }

    /* public clone(): any[] {
        return NitTools.Clone(this._locations);
    } */

    public static LocationById(id: string) {
        if (!id)
            return undefined;

        if (id.indexOf('_history') > -1)
            id = id.split('_history')[0];
        if (id.indexOf('/') > -1)
            id = id.split('/')[1];

        return this.__staticLocationsList?.find(o => o.id === id);
    }

    public async fetch(loadAll: boolean = false): Promise<any[]> {
        return new Promise<any[]>(async (resolve, reject) => {
            if (this._locations && this._locations.length > 0) {
                resolve(this._locations);
                return;
            }

            if (LocationService.loadingLocations) {
                await LocationService.waitForService();

                if (this._locations && this._locations.length > 0) {
                    resolve(this._locations);
                    return;
                }
            }

            LocationService.loadingLocations = true;

            // when reached this, there are currently no locations loaded.
            // so try to get the cached values
            if (!ConfigService.IsTest && sessionStorage.getItem('useLocationsCache') === '1') {
                let s = sessionStorage.getItem("locationsCache");
                if (s) {
                    try {
                        this._locations = JSON.parse(s);
                        if (this._locations && this._locations.length > 3) {
                            if (ConfigService.Debug) console.debug("Using locations from Location-Cached");
                            return resolve(this._locations);
                        }
                    } catch (e) {
                        console.warn(e);
                    }
                }
            }

            try {
                let identifier = `${NitTools.IncludeTrailingSlash(RuntimeInfo.SystemHeader)}locationWard|`;

                if (UserService.Login.usePrincipa) {
                    this.useSystem = false;
                    this.checkedUseSystem = true;
                } else {
                    if (!this.checkedUseSystem) {
                        let cntBundle = await this.fhirService.fetch(`Location?identifier=${identifier}&_summary=count`, false);
                        this.useSystem = typeof cntBundle.total === "number" && cntBundle.total > 0;
                    }
                }

                let url = "Location?_count=200&status=active";
                if (!loadAll && this.useSystem) {
                    url += `&identifier=${SystemHeaders.systemBase}locationWard|`;
                }

                await this.fhirService.fetch(url, true)
                    //await this.fhirService.fetch(url)
                    .catch(error => {
                        console.error(error);
                        reject(error);
                        return;
                    })
                    .then((result:any[]) => {
                        if (result) {
                            result.sort((a: any, b: any) => {
                                if (!a.name) a.name = "unnamed";
                                if (!b.name) b.name = "unnamed";
                                
                                return a.name.localeCompare(b.name);
                            });

                            if (loadAll && !this.hasLocations) this._locations = result;
                            result.forEach(r => this.insertLocation(r));                            
                        }

                        return resolve(this._locations);
                    })
            } finally {
                LocationService.loadingLocations = false;
            }
        });
    }
}
